(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/libs/qrcode-react/assets/javascripts/setup-namespace.js') >= 0) return;  svs.modules.push('/components/libs/qrcode-react/assets/javascripts/setup-namespace.js');


(function() {
  svs = svs || {};
  svs.components = svs.components || {};
  svs.components.libs = svs.components.libs || {};
  svs.components.libs.ReactQRCode = ReactQRCode || {};
})();


 })(window);